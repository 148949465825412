.enroll-card{
    width:900px;
    border-radius: 5px;
}
.f-r{
    float:right;
}
.lc-onboarding .ant-checkbox-wrapper{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between
}
.lc-onboarding .ant-checkbox{
    display: flex;
    justify-content: center;
    align-items: center;
}
.lc-onboarding .ant-checkbox-group-item{
flex-direction: row;
}
.lc-onboarding__card{
    padding: 10px;
    border-radius: 4px 0 0 4px;
    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.15);
    margin-left:20px;
    margin-bottom:10px;
    margin-top:10px;
    height: 150px;
    background-repeat: round;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.lc-onboarding__card .ant-checkbox-inner{
    position: relative;
    top:40%;
}
.lc-onboarding .chkbox{
    padding: 1.5em;
    /* border: 1px dashed #1890FF; */
    border-radius: 4px;
    border: 0.5px dashed #4A90E2;
    background-color: #ECF3FC;
}
.hr-line{
    padding: 1.5em 0em;
    border-top: 1px solid rgba(0,0,0,0.09);
}
.heading{
    padding-bottom: 8px;
}
.font-b{
    color: #0076FF;
}

/* LCEnroll1 */
.min-hgt{
    min-height: 105px !important;
}

/* .ant-upload-list-item-done{
    display: none;
} */


.lc-o1 .ant-row .ant-form-item{
    margin-bottom: 0px;
}

.lc-o1 .ant-form-item-label{
    padding-bottom: 1em;
}
