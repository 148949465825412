/* .reset-btn button{
    border: 1px solid #1890ff;
    color: #1890ff;
}
.reset-btn{
    margin-bottom: 0;
    padding-bottom:0;
} */

.reset-btn button{
    border: 1px solid #1890ff;
    color: #1890ff;
}
.reset-btn{
    margin: 0;
}
.hint{
    margin-bottom: 1.5em;
    font-size: 13px;
    color:#9B9B9B;
}
.ant-form-item-required:before{
    content: "";
}

.hr-line{
    padding: 1em 0em;
    border-top: 1px solid rgba(0,0,0,0.09);
}
.heading{
    padding-bottom: 8px;
}
