.interest_category{
    padding: 10px;
    height: 64px;
    border-left: 3px solid  #1890FF;
    border-radius: 4px 0 0 4px;
    box-shadow: 0 4px 12px 0 rgba(0,0,0,0.15);
    background-color: #FFFFFF;
    margin-left:20px;
    margin-bottom:10px;
    margin-top:10px;
    font-size: 16px;
}
.interest_category:nth-child(4n+1){
    margin-left:0
}
.interest-modal .ant-modal-footer,.pwd-modal .ant-modal-footer{
    border: 0;
}
.pwd-modal .ant-modal-body{
    padding-bottom: 0;
}
.hint{
    margin-bottom: 1.5em;
    font-size: 13px;
    color:#9B9B9B;
}
.titleColor{
    font-weight: 300;
    font-size: 16px;
}
.text-content{
    font-weight: 500;
    font-size: 18px;
}
.font18{
    font-size: 18px;
    font-weight: 500;
    color: #000000;
}
.edit-input{
    color: #000000;
    font-size: 16px;
    font-weight: 500;
}
.modal-popup{
border: 1px dashed #4A90E2;	
border-radius: 2px;	
background-color: rgba(74,144,226,0.1);
}
.button-border{
    border: 1px solid #4A4A4A;;
    color:#4A4A4A;;
}
.pwd-modal{
    width: 430px !important;
}
.font-15{
font-size: 15px;
}
.font-12 {
    font-size: 12px;
}
.font-14 {
    font-size: 14px;
}
.font-16 {
    font-size: 16px;
}
.text-black {
    color: #333333;
}
.btn-txt-black {
    color: #000000;
}
.font-weight-800 {
    font-weight: 800;
}
.green-btn,.green-btn:hover,.green-btn:focus{
    background-color: #68B415;
    border:  #68B415;
}
.profile-image {
    max-width: 401px;
    max-height: 401px;
    position: relative;
}
.profile-image .delete-img {
    position: absolute;
    top: 8px;
    right: 8px;
    margin: 7px;
    padding: 0 0.3rem;
    background-color: #FFFFFF;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
@media (max-width: 576px) {
    .max-dev-profile {
        display: none;
    }
    .dropdown-txt {
        color: #000000!important;
        font-weight: 800;
    }
    .mbl-profileBtn-txt {
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.65);
    }
    .hint {
        margin-bottom: 0;
        font-size: 12px!important;
        color: #828282!important;
    }
    .pwd-show {
        font-family: Avenir;
        font-style: normal;
        font-weight: 800;
        font-size: 10px;
        color: #000000;
    }
    .ant-modal-title {
        font-weight: 800;
        color: #000000;
    }
    .mbl-profile-img, .mbl-profile-img > img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }
    .profile-email-txt {
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        text-align: center;
        color: #828282;
    }
    .floating-btn {
        border-radius: 50%;
        background: #F2F2F2;
        top: 60%;
        right: 30%;
        width: 25px;
        height: 25px;
    }
    .blue-btn {
        background: #0076FF;
        font-family: Avenir;
        font-style: normal;
        font-weight: 800;
        font-size: 14px;
        color: #FFFFFF;
    }
    .mbl-edit-btn {
        display: flex;
        justify-content: space-between;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .w-130 {
        width: 130px;
    }
    .modal-upload .ant-upload {
        width: auto;
        height: auto;
    }
    .mb-6 {
        margin-bottom: 4rem!important;
    }
  }
@media (min-width: 576px) {
    .mbl-dev-profile {
      display: none;
    }
  }