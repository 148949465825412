/* body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
} */
.text-gray{
  color: #9B9B9B;
}
/* .ant-modal-close-x{
  display: none;
} */