.reg_studTitle {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    color: #000000;
}
.breadcrumbCursor {
    cursor: pointer!important;
}
.breadcrumbCursor:hover, .breadcrumbCursor:focus {
    cursor: pointer!important;
    color: #40a9ff!important;
}
.registeredBreadcrumb {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #000000;
}
.parentTag {
    background: #E6F7FF;
    color: #1890FF;
    border: 1px solid #91D5FF;
    box-sizing: border-box;
    border-radius: 4px;
    width: 110px;
    text-align: center;
}
.childTag {
    color: #13C2C2;
    background: #E6FFFB;
    border: 1px solid #87E8DE;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100px;
    text-align: center;
}
.adultTag {
    color: #722ED1;
    background: #F9F0FF;
    border: 1px solid #D3ADF7;
    box-sizing: border-box;
    border-radius: 4px;
    width: 110px;
    text-align: center;
}
.pendingTag {
    color: #FA8C16;
    background: #FFF7E6;
    border: 1px solid #FFD591;
    box-sizing: border-box;
    border-radius: 4px;
    width: 110px;
    height: 25px;
    text-align: center;
}
.registerTag {
    color: #52C41A;
    background: #F6FFED;
    border: 1px solid #B7EB8F;
    box-sizing: border-box;
    border-radius: 4px;
    width: 110px;
    height: 25px;
    text-align: center;
}
.notAttendedTag {
    color: #F5222D;
    background: #FFF1F0;
    border: 1px solid #FFA39E;
    box-sizing: border-box;
    border-radius: 4px;
    width: 110px;
    height: 25px;
    text-align: center;
}
.ant-tabs-tab.tab-color {
    color: #333!important;
}
.ant-tabs-tab.tab-color1 {
    color: #828282!important;
}
.meetingText {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 2px!important;
    margin-top: 2px!important;
}
.conText {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: 2px!important;
    margin-top: 2px!important;
    color: #333333;
}