.playlistCard {
  border-radius: 0.25rem;
  margin: 1rem;
  box-shadow: 2px 2px 9px 0px #d6d6d6;
}

.playlistCard .ant-card-body{
  height: 10.625rem;
}

.playlistCard .pTitle{
  text-transform: capitalize;
  color: black;
  font-weight: 500;
}

.linkedActivities .ant-list-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.viewPlistCollapse {
  margin: 2rem 0rem 2rem;
}

.viewPlistCollapse label{
  color: rgba(0,0,0,0.85);
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  margin-top: 1rem;
  text-transform: uppercase;
}

.viewPlistCollapse .age_groups .borderful {
  border-right: 1px solid #000;
  padding: 0 0.65rem;
}

.viewPlistCollapse .age_groups .borderful:last-child {
  border-right: 0px;
}

.viewPlistCollapse .ant-row {
  margin-bottom: 1rem;
}

.plistViewTitle {
   box-shadow: 0 1px 0 1px rgba(0,0,0,0.05);
   border-radius: 4px;
}
.plistViewTitle .ant-card-head{
   border: 0px;
   padding-left: 16px;
}

.plistViewTitle .ant-card-head-title{
   color: rgba(0,0,0,0.85);
   font-size: 16px;
   text-transform: uppercase;
}

.plistViewTitle .ant-card-body{
   padding: 5px 32px 5px 16px;
}

.view-playlist .playlist-title {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  text-transform: capitalize;
}

.linkedActivities .ant-list-item {
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
  padding: 10px;
  margin: 1rem;
  /* height: 50px; */
}

.linkedActivities .badge {
  border-radius: 4px;
  background-color: #36CFC9;
  color: #FFFFFF;
  height: 24px;
  width: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.linkedActivities span {
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  margin-left: 1rem;
}

.create-playlist-title .ant-card-body {
  padding: 2rem 1rem;
}

.createPlistCollapse {
  margin: 2rem 0;
}

.createPlistCollapse span {
  font-size: 16px;
}

.createPlistCollapse .interests-view-box {
  min-height: 40px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 8px;
}

.createPlistCollapse .link-act-lib svg{
  margin: 0
}

.createPlistCollapse .ant-list-item {
  border: 1px solid rgb(217, 217, 217);
  border-radius: 4px;
  padding: 10px;
  margin: 1rem;
}

.createPlistCollapse .badge {
  border-radius: 4px;
  background-color: #36CFC9;
  color: #FFFFFF;
  height: 24px;
  width: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.createPlistCollapse .list-name {
  font-size: 16px;
  font-weight: 500;
  color: rgb(0, 0, 0);
  margin-left: 1rem;
}
