.line-in-middle {
  background: linear-gradient(
    to right,
    transparent 0%,
    transparent calc(15% - 0.81px),
    #66beeb calc(15% - 0.8px),
    #66beeb calc(15% + 0.8px),
    transparent calc(15% + 0.81px),
    transparent 100%
  );
}

.play-audio-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  border: 1px solid #3ec0f1;
  border-radius: 15px;
}

.time-card {
  padding-left: 0;
  /*padding-right: 0;*/
  float: right;
  border: 0px;
  background: transparent;
}

.time-card > .ant-card-body {
  padding-left: 0;
  padding-right: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.time {
  font-family: Nunito, sans-serif;
  font-size: 18px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.67;
}
.time-card.journal .time {
  font-family: Nunito, sans-serif;

  font-size: 16px;
  line-height: 27px;
  /* identical to box height, or 169% */

  text-align: center;

  color: #000000;
}
.feedback-card {
  background-color: #ffffff;
  border: 1px solid #3ec0f1;
  border-radius: 5px;
  padding-bottom: 0;
}

.journal-card {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 118, 255, 0.08);
  border: none;
  border-radius: 5px;
  padding-bottom: 0;
}

.activity-card-completed {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 118, 255, 0.08);
  border: none;
  border-radius: 5px;
  padding-bottom: 0;
}

.activity-card {
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 118, 255, 0.08);
  border: none;
  border-radius: 5px;
  padding-bottom: 0;
}

.activity-card-completed > .ant-card-body {
  padding-bottom: 5px;
}

.card-title-pd {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 0;
}

.dot {
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #4a4a4a;
  margin: 8px;
}

.lc-name {
  color: #4a4a4a;
  font-size: 14px;
  margin: 8px 0;
  opacity: 0.67;
}

.activity-name {
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.67;
  margin: 16px 0 8px 0;
}

.activity-subtitle {
  color: #9a9a9a;
  mix-blend-mode: normal;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.activity-subtitle span {
  color: #3ec1f0;
  mix-blend-mode: normal;
}

.activity-done {
  margin: 16px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.activity-done > span {
  margin-left: 8px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.67;
}

.activity-completed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.activity-completed > span {
  font-size: 16px;
  color: #147ef9;
  mix-blend-mode: normal;
  opacity: 0.67;
  font-weight: 800;
  margin-left: 8px;
}

.journal-title {
  font-weight: 800;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.67;
  /*margin: 16px 0 8px 0;*/
}

.journal-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 16px;
  min-height: 20px;
}

.journal-content > img {
  width: 300px;
  height: 169px;
  object-fit: cover;
  border-radius: 5px;
}

.journal-data {
  font-size: 16px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.67;
  margin: 8px;
}

.feedback-response {
  background-color: #effbff;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
  margin-top: 16px;
  border-radius: 5px;
}

.feedback-response i {
  color: #3ec0f1;
  margin: 4px 8px 0 0;
  font-size: 16px;
}

.feedback-response p {
  margin: 0;
  color: #40b7f5;
  font-weight: 600;
  font-size: 16px;
}
.activityImage {
  width: 150px;
  height: 80px;
  border-radius: 5px;
}

.floatingFixed {
  position: fixed;
  z-index: 10;
  display: flex;
  top: 0;
  right: 0;
  /* background:#fff; */
  width: 0%;
  height: 100vh;
  overflow: scroll;
  max-height: 100vh;
  overflow: hidden;
  -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}
.floatingFixed > div {
  min-width: 100vw;
}
.floatingFixed.active {
  width: 100%;
  overflow: scroll;
}
.whiteContainer {
  background: #fff;
}
.heading {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}
.desc {
  font-family: Avenir;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #a0a0a0;
}
.desc.editor strong {
  font-weight: 900;
}
.journal-title-small {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}
.journal-title-link {
  font-family: Avenir;
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: #0084ff;
}
.journal-title-link.small {
  font-size: 14px;
}
.bg-colored {
  background: #f8fbff;
  height: 100%;
}
.opacityContainer {
  width: 20%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.48);
}
.fixedContainer {
  width: 80%;
  height: 100vh;
  background: #f8fbff;
  overflow: scroll;
}
.disabledContainer {
  opacity: 0.6;
  pointer-events: none;
}

.milestone-title {
  font-family: Avenir;
  font-weight: 800;
  font-size: 22px;
  line-height: 30px;
  text-transform: capitalize;
  color: #5432ba;
  margin-bottom: 8px;
}

.milestone-content {
  font-family: Avenir;
  font-size: 16px;
  line-height: 22px;
  color: #484848;
}

.milestone-count-bg {
  height: 89px;
  width: 89px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.milestone-count {
  font-family: Avenir;
  font-weight: bold;
  font-size: 17px;
  text-align: center;
  color: #ec1a58;
}

.journal-scrolltotop {
  position: fixed;
  bottom: 50px;
  right: 50px;
  background: #0076ff;
  border-radius: 6px;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 17px;
  cursor: pointer;
  z-index: 99;
}

.journal-notes {
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}

.share_img_size {
  width: 80%;
}

.share_center {
  justify-content: center;
}

@media only screen and (max-width: 576px) {
  .max-journal-titles, .play-audio-btn, .milestone-count-bg, .max-dev-workshopsComp {
    display: none!important;
  }
  .mbl-journal-card {
    background: #F8FBFF!important;
    border: 1px solid #F8FBFF!important;
  }
  .mbl-journal-cardRow {
    margin: 10px 0 15px 0!important;
  }
  .time-card > .ant-card-body {
    padding-right: 8px;
  }
  .time {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: #363636;
    opacity: unset;
  }
  .mbl-journal-img {
    width: 49px;
    height: 49px;
    margin-top: 2px!important;
  }
  .mbl-journal-img1 {
    width: 49px;
    margin-top: 2px!important;
  }
  .mbl-journal-Imgcomp {
    width: 32px;
    margin-top: 2px!important;
  }
  .categoryCard {
    min-width: 242px!important;
    max-width: 300px!important;
    height: 165px!important;
  }
  .activity-name {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: #212121;
    margin: 5px 0 0 0;
    opacity: unset;
  }
  .mbl-journal-Empty {
    width: 270px;
    height: 165px;
  }
  .journal-title {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    opacity: unset;
    color: #000000;
  }
  .mbl-journal-EmptyDate {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: #000000;
  }
  .mbl-journalFeed-img {
    width: 60%;
    margin-top: 2px!important;
  }
  .mbl-notes-modal {
    width: 100%!important;
    height: 100%!important;
  }
  .mbl-feedback-username {
    font-size: 12px!important;
  }
  .lc-name {
    color: #000000;
    opacity: unset;
  }
  .mbl-play-audio-btn {
    border: 1px solid #3EC0F1;
    box-sizing: border-box;
    padding: 10px;
    width: 50%;
    border-radius: 50px;
  }
  .milestone-title {
    font-size: 16px;
    line-height: unset;
  }
  .milestone-content {
    font-size: 14px;
  }
  .mbl-playlist-img {
    width: 45px;
  }
  .mbl-milestone-count-bg {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .milestone-count {
    font-weight: 800;
    font-size: 12px;
    margin-bottom: 2px;
  }
}
@media only screen and (min-width: 576px) {
  .mbl-journal-titles, .mbl-play-audio-btn, .mbl-milestone-count-bg, .mbl-dev-workshopsComp {
    display: none!important;
  }
}