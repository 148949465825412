.navbarContainer {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 21, 41, 0.12);
}
.navbarContainer > .ant-menu-horizontal > .ant-menu-item,
.navbarContainer > .ant-menu-horizontal > .ant-menu-submenu {
  font-size: 16px;
  font-weight: 500;
  color: #9b9b9b;
  padding: 0 10px;
}
.navbarContainer .ant-menu-item-group-list .ant-menu-item, .navbarContainer .ant-menu-item-group-list .ant-menu-submenu-title{
  padding:0 16px 0 10px;
}
.navbarContainer > .ant-menu-horizontal > .ant-menu-item > a {
  display: block;
  color: #9b9b9b;
  font-size: 13px;
}
.navbarContainer.expert > .ant-menu-horizontal > .ant-menu-item,
.navbarContainer.expert > .ant-menu-horizontal > .ant-menu-submenu,
.navbarContainer.expert > .ant-menu-horizontal > .ant-menu-item > a {
  color:#FFF;
}
.navbarContainer.parent > .ant-menu-horizontal > .ant-menu-item,
.navbarContainer.parent > .ant-menu-horizontal > .ant-menu-submenu,
.navbarContainer.parent > .ant-menu-horizontal > .ant-menu-item > a {
  color:#FFF;
}
.navbarContainer.lc > .ant-menu-horizontal > .ant-menu-item,
.navbarContainer.lc > .ant-menu-horizontal > .ant-menu-submenu,
.navbarContainer.lc > .ant-menu-horizontal > .ant-menu-item > a {
  color:#FFF;
}

.navbarContainer.expert .navSelected, .navbarContainer.expert .ant-menu-item > a:hover{
  font-weight: 900;
  color: #FFF !important;
}
.navbarContainer.parent .navSelected, .navbarContainer.parent .ant-menu-item > a:hover{
  font-weight: 900;
  color: #FFF !important;
}
.navbarContainer.lc .navSelected, .navbarContainer.lc .ant-menu-item > a:hover{
  font-weight: 900;
  color: #FFF !important;
}

.navbarContainer > .ant-menu-horizontal > li > .ant-menu-item-group-list {
  display: flex;
}

.navbarContainer > .ant-menu-horizontal > .ant-menu-item:hover,
.navbarContainer > .ant-menu-horizontal > .ant-menu-submenu:hover,
.navbarContainer > .ant-menu-horizontal > .ant-menu-item-active,
.navbarContainer > .ant-menu-horizontal > .ant-menu-submenu-active,
.navbarContainer > .ant-menu-horizontal > .ant-menu-item-open,
.navbarContainer > .ant-menu-horizontal > .ant-menu-submenu-open,
.navbarContainer > .ant-menu-horizontal > .ant-menu-item-selected,
.navbarContainer > .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid transparent;
  color: #0074fc;
  font-family: "Avenir", serif !important;
  font-weight: 800 !important;
}

.ant-menu-horizontal {
  border: 0px;
}

.active {
  color: green;
}

.navbarContainer .navSelected,
.navbarContainer .ant-menu-item > a:hover {
  color: #0076FF !important;
}

.navbarContainerAdmin {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: center;
  align-items: center;
  box-shadow: 0px 1px 4px rgba(0, 21, 41, 0.12);
}
.navbarContainerAdmin ul {
  background: #5432BA;
}
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-item,
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-submenu {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
}

.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-item > a {
  display: block;
  color: #FFFFFF;
  font-size: 13px;
}
.navbarContainerAdmin > .ant-menu-horizontal > li > .ant-menu-item-group-list {
  display: flex;
}
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-item:hover,
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-submenu:hover,
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-item-active,
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-submenu-active,
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-item-open,
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-submenu-open,
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-item-selected,
.navbarContainerAdmin > .ant-menu-horizontal > .ant-menu-submenu-selected {
  border-bottom: 2px solid transparent;
  color: #ffe20d;
  font-family: "Avenir", serif !important;
  font-weight: 800 !important;
}

.notification-lc-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 400px;
  border-bottom: 1px solid #c8c8c8;
}

.notification-content-name {
  font-weight: 900;
}

.navbarContainerAdmin .navSelected,
.navbarContainerAdmin .ant-menu-item > a:hover {
  color: #ffe20d !important;
}

.no-lc-notifications {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
}

.no-lc-notifications img {
  height: 150px;
  width: 156px;
}

.no-lc-notifications > .no-notifications-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #4A4A4A;
  font-size: 16px;
  margin-top: 16px;
}

.ant-menu-item.submenu-popup-scrollarea,.ant-menu-submnu-popup {
  height: 50vh;
  overflow: scroll;
  background:transparent !important;
}
.ant-menu-item:hover.notification-popup{
  color:rgba(0, 0, 0, 0.65);
}
.ant-menu-item.clearFilter{
  height: initial;
  background: #f8f8f8;
  color: #606060
}
.VersionContainer{
  position: fixed;
  bottom: 0;
  z-index: 998;
  right: 20px;
  background: #fff;
  padding: 8px 13px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 10px;
  font-family: Avenir;
font-style: normal;
font-weight: 500;
font-size: 10px;
/* line-height: 22px; */
box-shadow:  0px 1px 4px rgba(0, 0, 0, 0.25);
/* identical to box height, or 220% */
text-transform: uppercase;

color: #6F6F6F;
}
.navStrip{
  position: absolute;
  top: 0;
  overflow: hidden;
  left:-20px;
  bottom:0;
  right:0
}
.navStrip img{
  width:100%;
}
.expert.navbarContainer{
  background:#3EC1F0;
}
.expert.navbarContainer .ant-menu{
  background: #3EC1F0;
}
.expert.navbarContainer button{
  background: transparent;
  border-color: #fff;
  color:#fff;
}

.parent.navbarContainer{
  background: #5432BA;
}
.parent.navbarContainer .ant-menu{
  background: #5432BA;
}
.parent.navbarContainer button {
  background: transparent;
  border-color: #fff;
  color:#fff;
}

.lc.navbarContainer{
  background: #D8395C;
}
.lc.navbarContainer .ant-menu{
  background: #D8395C;
}
.lc.navbarContainer button {
  background: transparent;
  border-color: #fff;
  color:#fff;
}

.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu {
  vertical-align: middle;
}
a.selectedLine {
  position: relative;
}
a.selectedLine::before {
  content: "";
  position: absolute;
  top: 75%;
  width: 100%;
  height: 3px;
  background: linear-gradient(360deg,#fff,#fff 45.07%,#fff 80.05%);
  }
.parentNotiCount.ant-badge-count {
  background: #F8C947;
  border-radius: 8px;
  color: black;
}
@media (max-width: 576px) {
  .ant-badge-count {
    color: #000;
    font-weight: 900;
    background: #F8C947;
  }
  .parentMenu {
    display: none;
  }
  .ant-drawer-header {
    border-bottom: none!important;
  }
  .mbl-drawer {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    color: #000000;
  }
  .mbl-navText, .mbl-navText > a {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    color: #000000;
    margin-bottom: 10px;
  }
  a.navSelected {
    color: #4C39B3;
    font-weight: 800;
  }
  .ant-drawer-body {
    height: 90%;
  }
  .mbl-parentMenu {
    position: sticky;
    top: 0;
    z-index: 200;
  }
}
.Btn-NavAddC > span {
  text-align: center;
  padding-top: 6px;
}

@media (min-width: 576px) {
  .mbl-parentMenu, .mbl-sidemenu {
    display: none;
  }
}