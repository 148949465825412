@media only screen and (max-width: 576px) {
    .max-weeklyempty-img, .max-weekly-updates, .max-weekupdate-name, .max-weeklyCards {
        display: none!important;
    }
    .mbl-weekpicker {
        align-items: center!important;
        justify-content: center!important;
    }
    .mbl-weeklyempty {
        margin: 16px;
        padding: 16px;
    }
    .mbl-weeklyupdates-title {
        font-size: 16px;
        text-align: center;
        font-weight: 800;
    }
    .mbl-pd-10 {
        padding: 10px!important;
    }
    .mbl-weekupdate-name {
        font-weight: 800;
        font-size: 16px;
        color: #3C3C3C;
    }
    .ant-tabs-bar {
        margin: 0px 0px 16px 0px;
    }
    .mbl-weekCardsRow {
        margin: 0!important;
    }
    .mbl-viewAllCard {
        height: 218px;
        width: 211px;
        font-size: 16px;
        font-weight: 500;
        color: #000;
        text-align: center;
        padding: 100px 75px!important;
        box-shadow: 0px 4px 16px rgb(0 0 0 / 10%)!important;
        border-radius: 10px!important;
    }
    .cardTab {
        /* height: 400px; */
        overflow: scroll;
        flex-wrap: wrap;
    }
    .ant-tabs.ant-tabs-top.mbl-dev-ChildsTab.ant-tabs-line > div.ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content > div.ant-tabs-tabpane.ant-tabs-tabpane-active > div.pt-2 > div > div.ant-spin-nested-loading.w-100 > div > div > div.ant-tabs.ant-tabs-top.ant-tabs-line > div.ant-tabs-bar.ant-tabs-top-bar > div > span.ant-tabs-tab-next.ant-tabs-tab-arrow-show > span > i {
        color: #000;
    }
    .site-custom-tab-bar {
        z-index: 200;
        background: #fafafa;
        height: 55px;
      }
  }
@media only screen and (min-width: 576px) {
    .mbl-weeklyempty-img, .mbl-weekly-updates, .mbl-weekupdate-name, .mbl-weeklyCards {
        display: none!important;
    }
}