.ActivityCard .ant-card-body{
    height: 100%;
    padding: 20px;
}
.ActivityCard .ant-card-meta-detail{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}
.ActivityCard .ant-card-meta-title{
    font-family: 'Avenir';
    font-weight: 900 !important;
    font-size: 24px !important;
    z-index: 1;
    color: white;
}
.ActivityCard .ant-card-meta-description{
    font-family: 'Avenir';
    font-weight: 500 !important;
    font-size:19px !important;
    line-height: initial;
    z-index: 1;
    color: white;
}
.int-hgt{
    height: 45px;
}
.ActivityCard img{
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
}
.ActivityCardView .ant-card-meta-title{
    font-family: 'Avenir';
    font-weight: 500 !important;
    font-size: 16px !important;
    z-index: 1;
    margin-bottom: 1rem;

}
.ActivityCardView .ant-card-body{
    height: 100%;
    padding: 0;
}
.position-relative .floatingblockimage{
    bottom: 10px;
    right: 10px;
    background: rgba(255,255,255,.90);
    border-radius: .20rem;
}
.floatingblockimage img {
    margin-right: 8px;
}
.floatingblockimage img:last-child {
    margin-right: 0;
}
.ActivityCardView .ant-card-meta{
    margin: 0;
}
.ActivityCardView .ant-card-meta-detail{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding:15px;
}
.ActivityCardView .ant-card-meta-description,.text-card-black{
    color: #4A4A4A;
}

.interestCard .ant-card-meta-description {
  margin-top: 2.5rem;
}

.categoryCard .ant-card-head {
    border-bottom: 0;
}

.categoryCard .ant-card-meta-description {
    min-height: 105px;
    display: flex;
    flex-direction: column-reverse;
}

.categoryCard .triple-dot {
    border-radius: 50%;
    background: white;
    height: 5px;
    width: 5px;
}

.triple-dot:not(:last-child){
    margin-right: 3px;
}

.interestCard {
   box-shadow: 2px 2px 9px 0px #d6d6d6;
}

.interestCard .ant-card-head {
    border-bottom: 0;
}

.card-int-hgt{
    height: 100px;
}
.draft-tile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 20px;
    background-color: #DA375B;
    color: #FFFFFF;
    z-index: 10;
    text-align: center;
}

.image-card .ant-skeleton-active {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.image-card .ant-skeleton-paragraph {
    padding: 16px;
}
.dashboardCardStyle{
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    box-shadow: 0px 1px 0px rgba(0,0,0,0.05);
    border-radius: 4px;
    overflow: hidden;
    background: #ffffff;
    box-sizing: border-box;
    border-radius: 10px;
}