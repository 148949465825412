.sb-soundplayer-icon {
  width: 1em;
  height: 1em;
  position: relative;
  vertical-align: middle
}

.sb-soundplayer-progress-container {
  background-color: #dee0e1;
  width: 100%;
  height: 8px;
  min-width: 150px;
  overflow: hidden;
  cursor: pointer
}

.sb-soundplayer-progress-inner {
  background-color: #1890ff;
  height: 100%;
  transition: width .2s ease-in
}

.sb-soundplayer-cover {
  color: #fff;
  background-color: #f5f5f5;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
  padding: 30px 30px 20px;
  margin-bottom: 20px;
  height: 440px;
  position: relative;
  overflow: hidden;
  cursor: default;
  border-radius: 2px
}

.sb-soundplayer-cover:after {
  content: "";
  background: linear-gradient(rgba(0, 0, 0, .05), rgba(0, 0, 0, .35));
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0
}

.sb-soundplayer-cover .sb-soundplayer-info-box {
  display: inline-block;
  background-color: rgba(0, 0, 0, .8);
  padding: 5px 10px;
  margin-bottom: 5px;
  border-radius: 1px
}

.sb-soundplayer-cover .sb-soundplayer-track {
  font-weight: 600;
  font-size: 24px
}

.sb-soundplayer-cover .sb-soundplayer-artist {
  font-size: 18px
}

.sb-soundplayer-cover-logo {
  color: hsla(0, 0%, 100%, .9);
  width: 100px;
  height: 14px;
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 2
}

.sb-soundplayer-volume {
  position: relative;
  transition: background .8s ease;
  border-radius: 50px;
  padding:10px
}
.sb-soundplayer-volume:hover{
  background:#e2e2e2;
  margin-right: 10px;

}
.sb-soundplayer-volume:hover div {
  width: 80px;
  overflow: initial;

}

.sb-soundplayer-volume div {
  overflow: hidden;
  width: 0;
  margin-bottom:0;
  display: flex;
  transition: width .2s ease-out;
  position: relative
}
/* .sb-soundplayer-volume:hover div{

} */
.sb-soundplayer-volume-range {
  -webkit-appearance: none;
  /* margin-top: 10px; */
  /* margin-left:10px; */
  width: 98%;

}

.sb-soundplayer-volume-range:focus {
  outline: none
}

.sb-soundplayer-volume-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .2em;
  cursor: pointer;
  animate: .2s;
  background: #f1f1f1;
  border-radius: 1.3px
}

.sb-soundplayer-volume-range::-moz-range-track {
  width: 100%;
  height: .2em;
  cursor: pointer;
  animate: .2s;
  background: #f1f1f1;
  border-radius: 1.3px
}

.sb-soundplayer-volume-range::-ms-track {
  width: 100%;
  height: .2em;
  cursor: pointer;
  animate: .2s;
  background: #f1f1f1;
  border-radius: 1.3px
}

.sb-soundplayer-volume-range::-moz-range-thumb {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: #1890ff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -.4em
}

.sb-soundplayer-volume-range::-webkit-slider-thumb {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: #1890ff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -.4em
}

.sb-soundplayer-volume-range::-ms-thumb {
  height: 1em;
  width: 1em;
  border-radius: 1em;
  background: #1890ff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -.4em
}

.sb-soundplayer-volume-range::-ms-fill-lower {
  background: #1890ff
}
.sb-soundplayer-timer{
  /* min-width: 100px; */
  white-space: nowrap;
  font-size: 12px;
  color: #000;
  font-weight: 500;
}
.sb-soundplayer-progress-container{
  height: 5px;
}
.rounded-left {
  border-radius: 3px 0 0 3px
}

.button-transparent {
  position: relative;
  z-index: 2;
  color: inherit;
  background-color: transparent;
  border-radius: 0;
  border: 1px solid transparent
}

.button-transparent:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: -var(--border-width);
  right: -var(--border-width);
  bottom: -var(--border-width);
  left: -var(--border-width);
  border: 1px solid transparent;
  background-color: currentcolor;
  opacity: 0
}

.button-transparent:hover {
  box-shadow: none
}

.button-transparent:focus:before,
.button-transparent:hover:before {
  opacity: .09375
}

.button-transparent:focus {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 0 2px
}

.button-transparent.is-active,
.button-transparent:active {
  box-shadow: none
}

.button-transparent.is-active:before,
.button-transparent:active:before {
  opacity: .0625
}

.button-transparent.is-disabled,
.button-transparent:disabled {
  opacity: .5
}
.flex {
  display: -ms-flexbox;
  display: flex
}
.flex-column {
  -ms-flex-direction: column;
  flex-direction: column
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}
.flex-center {
  -ms-flex-align: center;
  align-items: center
}
.mr2 {
  /* margin-right: 16px; */
  padding-right: 1rem
}

.flex-none {
  -ms-flex: none;
  flex: none
}

.button {
  font-family: inherit;
  font-size: inherit;
  font-weight: 700;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  padding: 8px 16px;
  padding: .5rem 1rem;
  margin: 0;
  height: auto;
  border: 1px solid transparent;
  vertical-align: middle;
  -webkit-appearance: none
}

.button,
.button:after,
.button:before
 {
  box-sizing: border-box
}

::-moz-focus-inner {
  border: 0;
  padding: 0
}

.button:hover,.button:focus {
  text-decoration: none;
  box-shadow: none;
}
.transform-180,.sb-soundplayer-volume-btn{
  transform: rotate(180deg)
}
.sb-soundplayer-volume-btn,.sb-soundplayer-play-btn{
  padding-top:0;
  padding-bottom: 0;
  padding-right: 10px;
  padding-left: 10px;
}
.sb-soundplayer-volume-btn{
  padding-right: 0;
  margin-right: 10px;
  padding-left: 0;
}
.sb-soundplayer-play-btn{
  padding-right: 10px;
}
.sb-soundplayer-play-btn svg{
  width:.7rem;
}
.audioContainer{
  /* padding-left:30px; */
  background: #f1f3f4;
  border-radius: 50px;
}
.pointer-events-none{
  pointer-events: none;
}