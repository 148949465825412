@media only screen and (max-width: 576px) {
    .max-dev-workshopView {
      display: none!important;
    }
    .mbl-dev-workshopView {
        background: #fafafa;
    }
    .workshoptypeText, .hurryText {
      font-size: 12px;
    }
    .title {
      font-size: 14px;
      font-weight: 800!important;
      color: #828282;
    }
    .desc {
      color: #333!important;
    }
    .long-description {
      font-size: 14px;
      padding: 0;
    }
    .contentCreat {
      font-weight: 800;
      font-size: 14px;
      color: #828282;
    }
    .ContentDiv {
      width: 85px;
      height: 85px;
    }
    .ContentImg {
      margin: 4px;
      width: 50px;
      height: 50px;
    }
    .mbl-content-creator {
      background: #FFFFFF;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }
    .bioDetail {
      font-size: 12px;
    }
    .mbl-bottom-btn {
      box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.08);
      width: 100%;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 12px;
    }
    .mbl-bottom-btn > h6 {
      color: #EA1F59;
    }
    .btn-inProgress, .btn-addCart, .btn-addCart:hover, .btn-addCart:focus {
      width: 328px;
      font-size: 16px;
    }
    .mt-mb-6 {
      margin: 3.5rem 0 6.5rem 0;
    }
    .mbl-listImg {
      margin-top: .5rem;
    }
    .sliderMainImg {
      width: 100%;
      height: 184px;
    }
    .mbl-list-img {
      width: 100px!important;
      height: 60px!important;
    }
    .min-w-80per {
      min-width: 80%!important;
    } 
    .w-80per {
      width: 80%!important;
    }
    .RegText {
      font-size: 14px;
    }
    .registerTag {
      font-size: 12px;
      background: #F0FFE0!important;
      color: #68B415!important;
      border: 1px solid #68B415!important;
    }
    .min-w-200 {
      min-width: 200px;
    }
    .w-200 {
      width: 200px;
    }
    .mbl-h-videoPlayer {
      height: 165px!important;
    }
  }
@media only screen and (min-width: 576px) {
    .mbl-dev-workshopView, .mbl-scroll-smallimg {
      display: none!important;
    }
    .mbl-h-videoPlayer {
      height: 370px!important;
    }
  }