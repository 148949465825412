
  @media only screen and (max-width: 576px) {
    .max-dev-feedback-title, .table-full {
      display: none!important;
    }
    .Mbl-Feedback-breadcumb {
        color: #0076FF;
        font-family: Avenir;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
    }
    .mbl-main-div {
      margin: 1rem!important;
    }
    .mbl-Feedbackcard {
      background: #FFFFFF;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
      border-radius: 4px;
    }
    .mbl-feedDates {
      border-radius: 12px;
      height: 20px;
      text-align: center;
    }
    .play-audio-btn {
      border: 1px solid #42C0EF;
    }
    .feedType-img {
      width: 42px;
      height: 42px;
      box-sizing: border-box;
      border-radius: 4px;
      margin-right: 10px;
    }
    .tagGeneral {
      font-size: 12px;
      color: #FF7A00;
      width: 65px;
      height: 20px;
      background: #FFEBD9;
      border-radius: 2px;
    }
    .mbl-feed-play-audio-btn {
      border: 1px solid #3EC0F1;
      box-sizing: border-box;
      padding: 5px 15px;
      width: auto;
      border-radius: 30px;
    }
  }

  @media only screen and (min-width: 576px) {
    .mbl-dev-feedback-title, .Mbl-feedback-empty, .mbl-FeedbacksCards {
      display: none!important;
    }
  }