.FilterCard .ant-card-body {
	padding: 0;
}
.FilterCard .ant-collapse-header {
	justify-content: space-between;
	padding: 1rem !important;
	font-family: 'Avenir' !important;
	font-weight: 500 !important;
	font-size: 19px;
	background: white;
}
.ParentFilterCard .ant-card-body {
	padding: 0;
}
.ParentFilterCard .ant-collapse-header {
	justify-content: space-between;
	padding: 1rem !important;
	font-family: 'Avenir' !important;
	font-weight: 500 !important;
	font-size: 19px;
	background: white;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
	font-style: normal;
	vertical-align: -.125em;
	text-align: center;
	text-transform: none;
	line-height: 0;
	font-size: 12px;
	position: absolute;
	display: inline-block;
	line-height: 46px;
	vertical-align: top;
	top: 50%;
	margin-top: 2px;
	transform: translateY(-50%);
	right: 16px;
}
.FilterCard .ant-collapse > .ant-collapse-item {
	border: 0;
}
.FilterCard .ant-collapse {
	border: 0;
}
.FilterCard .ant-collapse-content {
	border-top: 0;
}
.FilterCard .ant-collapse-content > .ant-collapse-content-box {
	border-top: 0;
	border-bottom: 1px solid #e8e8e8;
}
.FilterCard .ant-input {
	border: 0;
	border-bottom: 1px solid;
	border-radius: 0;
}
.FilterCard .ant-input:focus {
	outline: none;
	box-shadow: none;
}
.FilterCard div.ant-collapse > div:nth-child(2) > div.ant-collapse-header {
	/* padding-bottom: 0 !important; */
}
.ParentFilterCard .ant-collapse > .ant-collapse-item {
	border: 0;
}
.ParentFilterCard .ant-collapse {
	border: 0;
}
.ParentFilterCard .ant-collapse-content {
	border-top: 0;
}
.ParentFilterCard .ant-collapse-content > .ant-collapse-content-box {
	border-top: 0;
	border-bottom: 1px solid #e8e8e8;
}
.ParentFilterCard .ant-input {
	border: 0;
	border-bottom: 1px solid;
	border-radius: 0;
}
.ParentFilterCard .ant-input:focus {
	outline: none;
	box-shadow: none;
}
.ParentFilterCard div.ant-collapse > div:nth-child(2) > div.ant-collapse-header {
	/* padding-bottom: 0 !important; */
}
.FilterCard {
	height: calc(100vh - 90px);
	overflow: scroll;
}
.ParentFilterCard {
	height: calc(140vh - 20px);
	overflow: scroll;
}
.ChildrenImgFilter {
	height: 35px;
	width: 35px;
	border-radius: 50%;
	border: 1px solid #828282;
	padding: 3px;
}
.childFilterText {
	font-family: Avenir;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 22px;
	color: #333333;
	text-transform: capitalize;
	padding: 10px;
}

@media only screen and (max-width: 576px) {
	.ParentFilterCard {
	  display: none!important;
	}
  }
