

.successMain {
    width: 620px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.payment-title {
    font-family: Avenir;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 37px;
    color: #000000;
    text-align: center;
}
.payment-para {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #000000;
}
.workcartimg {
    border-radius: 5px;
    object-fit: cover;
}
.paymentLoading {
    font-size: 18px;
    color: #ffffff;
    font-weight: 500;
    margin: 20px;
}

b {
    font-weight: 800;
  }