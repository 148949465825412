.interest-image .clearfix .ant-upload-select-picture-card{
  height: 130px;
  width: 130px;
  margin: 1rem 0;
}

.interest-image .clearfix .ant-upload-list-item-done, .interest-image .clearfix .ant-upload-list-item-uploading {
  height: 130px;
  width: 130px;
  margin: 1rem 0;
}

.interest-image .clearfix img {
  height: 110px;
  width: 110px;
}

.mb-8px {
  margin-bottom: 8px;
}

.edit-button svg {
  margin: 0;
}
