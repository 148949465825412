.pwd-show{
        top: 11px;
    right: 12px;
}
.form-error{
    position: absolute;
    /* bottom: 5px; */
    font-size: 12px;
    left: 0;
    color: #f5222d;
    display: none;
}
.form-error1{
    position: absolute;
    top: 40px;
    font-size: 12px;
    left: 0;
    color: #f5222d;
    display: none;
}
.formSubmitted, .form-error, .form-error1{
    display: block;
}
.formSubmitted .is-invalid-input{
    border-color: #f5222d
}