.add-collec-btn, .add-collec-btn:hover, .add-collec-btn:focus {
  background: #68B415;
  border: #68B415 1px solid;
}

.delete-btn, .delete-btn:hover, .delete-btn:focus {
  background: #f40000;
  border: #f40000 1px solid;
}

.btn-w250 {
  width: 250px;
}

.btn-w250[disabled]:hover, .btn-w250[disabled]:focus {
  color: rgba(0, 0, 0, 0.25)!important;
  background-color: #f5f5f5!important;
  border-color: #d9d9d9!important;
  text-shadow: none!important;
  -webkit-box-shadow: none;
  box-shadow: none!important;
}

.btn-w250:hover, .btn-w250:focus {
  color: #ffffff!important;
  border-color: #1890ff!important;
}

.workshopApprbtn, .workshopApprbtn:hover, .workshopApprbtn:focus  {
  width: 200px;
  color: #ffffff!important;
  background: #68B415!important;
  border-radius: 4px;
  border-color: #68B415!important;
}

.workshopApprbtn[disabled], .workshopApprbtn[disabled]:hover, .workshopApprbtn[disabled]:focus {
  color: rgba(0, 0, 0, 0.25)!important;
  background-color: #f5f5f5!important;
  border-color: #d9d9d9!important;
  text-shadow: none!important;
  -webkit-box-shadow: none;
  box-shadow: none!important;
}

.btn-w300 {
  width: 300px;
}

.btn-w350 {
  width: 350px;
}
