.upload-btn {
  /*height: 128px;*/
  /*width: 128px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #d9d9d9;
  background-color: #fafafa;
  cursor: pointer;
}

.upload-btn:hover {
  border: 1px dashed #007bff;
}

.upload-btn svg {
  margin: 0;
}

.modal-upload .ant-upload {
  height: 200px;
  width: 400px;
}

.delete-image-btn {
  position: absolute;
  top: 3%;
  right: 3%;
  padding: 0px 0.3rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-collection {
  position: absolute;
  top: 3%;
  right: 3%;
  padding: 0px 0.3rem;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.floating-btn {
  background-color: rgb(255, 255, 255);
  height: 20px;
  width: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.ReactCrop__image {
  max-height: initial;
}
.svgUpload{
  color: #007bff;
  width: 30px;
  height: 30px;
}