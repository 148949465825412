.circle-indicator {
    width: 10px;
    height: 10px;
    background: transparent;
  }

  .workshop-btncolor {
    color: #ffffff!important;
    background: #0076FF!important;
    border-color: #0076FF!important;
  }

  .workshop-Upcomingbtncolor {
    color: #ffffff!important;
    background: #4C39B3!important;
    border-color: #4C39B3!important;
  }

  .workshopupdated-box {
    height: 22px;
    width: 80px;
    background: #EA1F59;
    border: 1px solid #EA1F59;
    border-radius: 4px;
    color: #FFFFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }
  
  .ant-collapse-header {
    background: #e2f4ff;
    border-color: #e2f4ff;
    font-family: "Avenir", sans-serif !important;
    font-weight: 100 !important;
    font-size: 16px;
  }

  .pendingBtn-w250 {
    width: 250px;
  }
  
  .border-bottom {
    border-bottom: 1px solid #dee2e6 !important;
  }
  
  .box-sdw-rad {
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.05);
  }
  
  .featured-img {
    border-top: 1px solid #e8e8e8;
    padding: 1rem;
  }
  
  .b-r {
    border-right: 1px solid #d8d8d8;
  }
  
  .pdl-2 {
    padding-left: 2rem;
  }
  
  .featured-img img,
  .steps-img img {
    height: auto;
    width: auto;
    border-radius: 5px;
  }
  
  .mashupdata .ant-collapse-content-box {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  
  .activity-img img {
    height: auto;
    width: auto;
    border-radius: 5px;
  }
  
  .activity-img {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    border: 1px dashed rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 1em 0;
    margin: 0 auto;
  }
  
  .br-5 {
    border-radius: 5px;
  }
  
  .steps-desc {
    height: 10.63rem;
    overflow-y: scroll;
  }
  
  .steps-img img {
    height: 229px;
    /* width: 383.6px; */
    width: 100%;
  }
  
  .steps-img-wrap {
    height: 229px;
    width: 384px;
  }
  
  .steps-img {
    background-color: #fcfeff;
  }
  
  .ptb-8 {
    padding: 0.5rem 0;
  }
  
  .ptb-4 {
    padding: 1.5rem 0;
  }
  
  .b-l {
    border-left: 1px solid #d8d8d8;
  }
  .titleCard {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    text-align: center;
    margin-top: 19px;
    margin-bottom: 19px;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 137% */
  
    color: rgba(0, 0, 0, 0.85);
  }
  
  .view-book-lib {
    margin: 32px 32px 0px 32px;
    padding-bottom: 32px;
  }
  .border-style {
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.05);
    padding: 24px;
  }
  .pb-18 {
    padding-bottom: 1.125rem;
  }
  .mtb-34 {
    margin: 2.125rem 0px;
  }
  .title {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
  }
  .mtb-24 {
    margin: 1.5rem 0px;
  }
  .desc {
    font-family: Avenir;
    font-size: 16px;
    line-height: 22px;
    padding: 10px;
  }
  .long-description {
    font-family: Avenir;
    font-size: 16px;
    line-height: 22px;
    padding: 10px;
  }
  .back-button {
    float: right;
    position: relative;
    bottom: 75px;
  }
  
  .featured-img {
    border-top: 0px !important;
    padding: 1rem;
  }
  .featured-img img {
    height: auto;
    width: 378px;
    border-radius: 5px;
  }
  
  .color-blue {
    color: #0076ff;
  }
  .color-red {
    color: #ff0000;
  }
  .text-area-style {
    color: #000000;
    background: white;
  }
  .b-l {
    border-left: 1px solid #d8d8d8;
  }
  .btn-changeHover, .btn-changeHover:hover, .btn-changeHover:hover {
    color: #ffffff;
    border-color: #1890FF;
    border-radius: 4px;
    background: #1890FF;
  }
  .Changecomment-border {
    background: #FFF6D6;
  }
  .Changecomment-icon {
    color: #F8C947;
    font-size: 24px;
    text-align: center;
  }
  .Changecomment {
    text-align: center;
    font-size: 16px;
    margin: 4px;
  }
  .contentcreateLab {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 147.2%;
    color: #000000;
  }
  .tagStatusPending {
    background: #FFF7E6;
    color: #FA8C16;
    border: 1px solid #FFD591;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .tagStatusRejected {
    background: #FFDCE6;
    color: #EA1F59;
    border: 1px solid #EA1F59;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .tagStatusApproved {
    background: #EEFFDB;
    color: #68B415;
    border: 1px solid #68B415;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .registeredstudBtn-w250, .registeredstudBtn-w250:hover, .registeredstudBtn-w250:focus {
    /* width: 250px; */
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    text-transform: capitalize;
    color: #0076FF;
    border: 1px solid #0076FF;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .fireimg {
    padding-left: 5px;
    padding-right: 5px;
  }
  .plusmoreTag {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #fff;
  }
  .featuredTitle {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.85);
  }
  .workTitle {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    color: #FFFFFF;
  }
  .sort {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: #4A4A4A;
  }
  .sort-active {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    color: #0076FF;
  }
  .dateTime {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
  }
  .user-profile-img {
    border-radius: 50%;
    width: 65px;
    height: 65px;
  }
  .featuredUpcomingworkshop {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
  }
  .ant-carousel .slick-slide {
    text-align: center;
    overflow: hidden;
    /* background: #364d79; */
  }

  .parentWorkview {
    color: #57479C;
  }

  .carousalImg {
    width: 100%;
  }

  .carousalImg1 {
    width: 100%;
    cursor: pointer;
  }

  .caroactive, .carousalImg1:active {
    background: #FFFFFF;
    width: 100%;
    border: 1px solid #4F4F4F;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
    padding: 3px;
  }

  .heading {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #000000;
  }
  .dateText {
    color: #828282;
    font-family: Avenir;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
  }
  .workshoptypeText {
    color: #000000;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  .WorkshopViewTitleText {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #828282;
  }
  .workRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: start;
    /* float: left; */
    width: 100%;
    margin-bottom: 10px;
  }
  .workCol {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    float: left;
    margin-right: 10px;
    padding-right: 10px;
  }
  .hurryText {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    align-items: center;
    color: #EA1F59;
  }
  .dateDiv {
    align-items: flex-start;
    padding: 5px;
    background: #F9F9F9;
    border-radius: 5px;
  }
  .workDate {
    position: static;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #333333;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 5px 0px;
  }
  .workTime {
    position: static;
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #828282;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 5px 0px;
  }
  .reqText {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 22px;
    text-transform: capitalize;
    color: #333333;
    margin-top: 10px;
  }
  .comContentDiv {
    position: relative;
    float: left;
  }
  .ContentDiv {
    position: relative;
    width: auto;
    height: auto;
    border-radius: 10px;
    padding: 8px;
    text-align: center;
    box-shadow: 10px 10px #FFF;
  }
  .ContentDiv1 {
    position: relative;
    width: auto;
    height: auto;
    border-radius: 4px;
    margin-right: 10px;
    text-align: center;
    box-shadow: 10px 10px #FFF;
  }
  .Conhead1 {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    text-align: left;
    color: #000000;
  }
  .bioDetail {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #000000;
    text-align: start;
  }
  .ContentImg {
    margin: 10px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
  }
  .contentName {
    font-family: Avenir;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: #fff;
  }
  .arrowIcon {
    color: #333333;
  }
  .btn-addCart, .btn-addCart:hover, .btn-addCart:focus {
    background: #57479C;
    border-radius: 5px;
    color: #fff;
    border: none;
    width: 200px;
  }
  .btn-inProgress {
    background: #BDBDBD!important;
    color: #fff!important;
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    border-radius: 5px;
  }
  .contentCreat {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    line-height: 25px;
    text-align: left;
    color: #000000;
  }
  .RegText {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    text-align: left;
    color: #000000;
  }
  .regTex {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
  }
  .regDateTitleText {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333333;
  }
  .regDateText {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #828282;
  }
  .btn-register, .btn-register:hover, .btn-register:focus {
    width: 100%;
    background: #EA1F59;
    border-radius: 4px;
    border: none;
  }
  .carttotal-text {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 161.1%;
    color: #000000;
    float: left;
  }
  .carttotal-amt {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 161.1%;
    color: #000000;
    float: right;
  }
  .purchase-heading {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 161.1%;
    color: #000000;
  }
  .cartitem-text {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 161.1%;
    color: #000000;
    float: left;
  }
  .cartitem-amt {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 161.1%;
    color: #000000;
    float: right;
  }
  .purchase-row {
    width: 100%;
    position: relative;
    display: inline-block;
  }
  .workcartimg {
    width: auto;
    height: 200px;
  }
  .selectchildtext {
    font-family: Avenir;
    font-size: 14px;
    align-items: center;
    color: #000000;
  }
  .editicon {
    color: #0076FF;
    font-size: 16px;
    position: absolute;
    margin-left: 10px;
    cursor: pointer;
  }
  .linebar {
    border: 1px solid #828282;
    transform: rotate(90deg);
    margin: 0px 15px;
  }
  .btns-cart {
    float: right;
  }
  .btn-save {
    cursor: pointer;
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
  .btn-save:hover, .btn-save:focus {
    color: rgb(66, 192, 239)!important;
  }
  .btn-del {
    cursor: pointer;
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
  }
  .btn-del:hover, .btn-del:focus {
    color: #EA1F59!important;
  }
  .featureTitle {
    border-radius: 0px 4px 0px 0px;
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
  }
  .preqTextDiv {
    position: relative;
    background: #FFF6D6;
    border-radius: 2px;
  }
  .preqText {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    text-transform: capitalize;
    color: #333333;
    padding: 5px;
  }
  .preqTextBord {
    background: #F8C947;
    border: 2px solid #F8C947;
    margin-right: 5px;
  }
  .joinWorkshop-div {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.08);
    padding: 15px 30px;
  }
  .joinContentText {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
  }
  .topbannerImg {
    max-height: 100%;
    min-width: 100%;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;
    border-radius: 10px;
  }
  @media (min-width: 1140px) {
    .search-parent {
      width: 400px;
    }
  }
  .featuredTitleLen {
    display: flex;
    justify-content: end;
    margin-top: 5px;
    align-items: center;
  }
  .featuredEmpty {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
  }
  @media (max-width: 1280px) {
    .ContentImg {
      margin: 8px;
      width: 65px;
      height: 65px;
      border-radius: 50%;
    }
    .contentName {
      font-size: 10px;
    }
  }
  .min-w-300 {
    min-width: 425px;
    @media (min-width: 350px) {
      min-width: 325px;
    }
    @media (min-width: 1100px) {
      min-width: 425px;
    }
  }
  .w-300 {
    width: 425px;
    scrollbar-width: none;
    @media (min-width: 350px) {
      width: 325px;
    }
    @media (min-width: 1100px) {
      width: 425px;
    }
  }
  .w-400 {
    width: 400px;
  }
  .meetingBtn {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    text-transform: capitalize;
    color: #0076FF;
    border: 1px solid #0076FF;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .primaryHostText {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }
  .notAttendedTag {
    color: #EA1F59;
    background: #FFDDE4;
    border: 1px solid #EA1F59;
    box-sizing: border-box;
    border-radius: 4px;
    width: 110px;
    text-align: center;
}
.TaxText {
  font-family: Avenir;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #828282;
}
.toggle-Btn-Txt {
  font-family: Avenir;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: #333;
}
.toggle-Btn > .ant-switch {
  background-color: #EC1A58;
}
.toggle-Btn > .ant-switch-checked {
  background-color: #68B415;
}

@media only screen and (max-width: 576px) {
  .max-dev-d-none, .max-dev-topbanner {
    display: none!important;
  }
  .createActivity {
    background: transparent!important;
  }
  .mbl-workshopList-h {
    /* height: calc(100vh - 215px)!important; */
    overflow: unset;
  }
  .tabscroll {
    height: auto!important;
    overflow: unset!important;
  }
  .mbl-padding-0 {
    padding: 0!important;
  }
  .mbl-pl-none {
    padding-left: 15px!important;
  }
  .mbl-dev-w-search {
    padding: 0!important;
  }
  .search-parent::placeholder {
    font-size: 14px;
  }
  .topbannerImg {
    border-radius: 0;
  }
  .mbl-min-w-300 {
    min-width: 0px;
  }
  .mbl-w-90 {
    width: 90px!important;
  }
  .mbl-w-tab {
    text-align: center;
    height: 35px;
  }
  .mbl-w-tab.active {
    border-bottom: 3px solid #0076FF;
  }
  .mbl-w-tab > span {
    color: #828282;
    font-weight: normal;
    font-size: 16px;
  }
  .mbl-w-tab.active > div {
    color: #0076FF;
    font-weight: 800;
  }
  body {
    overflow-y: unset!important;
  }
  .mbl-nav-sticky {
    position: sticky;
    top: 0;
    z-index: 200;
    background: #fafafa;
    height: 55px;
    padding: 16px;
  }
}

@media only screen and (min-width: 576px) {
  .mbl-dev-w-search, .mbl-dev-d-none,
  .mbl-dev-topbanner {
    display: none!important;
  }
}