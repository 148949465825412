.no-students {
  height: calc(100vh - 70px);
  overflow: scroll;
}

.student-image {
  border-radius: 50%;
  background-color: #D3E8FF;
  border: 1px solid #0078FF;
  height: 100px;
  width: 100px;
}

.student-image span {
  font-size: 20px;
  color: #0078FF;
}

.student-plus {
  border-radius: 50%;
  background-color: #c4c4c4;
  height: 100px;
  width: 100px;
}

.student-plus > .anticon svg,
.studentView .anticon svg {
  margin: 0;
}

.student-name-container {
  min-height: 65px;
  min-width: 150px;
  margin-right: 32px;
  padding-bottom: 16px;
}

.student-selected {
  border-bottom: 3px solid #0076ff;
}

.student-name-image {
  margin-right: 16px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  /* background: #FFFFFF; */
  /* border: 1.5px dashed #0076FF; */
  /* color: #1890FF; */
}

.student-selected > .student-name-image {
  background: #FFFFFF;
  border: 1px solid #0076FF;
}

.ChildrenImgFilter-det {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  padding: 5px;
}

.student-name-plus {
  margin-right: 16px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #e0e0e0;
  border: 1px dashed #C4C4C4;
}

.student-name-details span:first-child {
  font-weight: 800;
  font-size: 16px;
  color: #000000;
}

@media only screen and (max-width : 430px) {
  .student-name-container {
    padding: 16px;
  }

  .student-selected {
    border-left: 3px solid #0076ff;
    border-bottom: 0px;
  }

  .student-list {
    flex-wrap: wrap;
  }
}

@media only screen and (max-height : 450px) {
  .no-students {
    height: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .student-list, .max-dev-student-name, .max-dev-Childmodal, .max-student-container, 
  .mbl-border-margin, .max-dev-carddet, .max-dev-ChildsTab {
    display: none!important;
  }
  .studentView {
    margin: 1rem 1rem 0rem;
  }
  .student-name-plus {
    margin-right: 10px;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background: #FFFFFF;
    border: 1.5px dashed #0076FF;
  }
  .student-name-details span:first-child {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #0076FF;
  }
  .App, #root {
    background: #F2F2F2;
  }
  .student-name-container1 {
    min-height: 50px;
    min-width: auto;
    margin-right: 15px;
    padding-bottom: 10px;
  }
  .student-name-image {
    margin-right: 10px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background: #FFFFFF;
    /* border: 1px dashed #333; */
    /* color: #333; */
  }
  .mbl-student-selected > .student-name-image {
    border: 1.5px solid #0076FF;
    color: #1890FF;
  }
  .mbl-student-selected > .mbl-stud-name {
    color: #1890FF;
    font-weight: 800;
  }
  .mbl-stud-name {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #333;
    white-space: nowrap;
  }
  .mbl-student-selected {
    border-bottom: 3px solid #0076FF!important;
  }
  .ant-modal-body {
    padding: 15px;
  }
  .mb-5px {
    margin-bottom: 5px!important;
  }
  .hint {
    font-family: Avenir!important;
    font-style: normal!important;
    font-weight: normal!important;
    font-size: 11px!important;
    color: #000000!important;
  }
  .mbl-dev-student-name {
    background: #FFFFFF;
    padding: 5px;
    width: 100%;
    overflow: scroll;
    flex-wrap: nowrap;
  }
  .mbl-studdet {
    padding: 0!important;
  }
  .mbl-m-0, .jounalTabDatepic {
    margin: 0!important;
  }
  .mbl-studname-head {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 800;
  }
  .ant-card-body {
    padding: 10px;
    width: auto;
  }
  .mbl-child-title {
    font-family: Avenir;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #828282;
  }
  .mbl-child-name {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #333333;
  }
  .feedbackText {
    font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0076FF;
  }
  .mbl-child-redtag {
    color: #F5222D;
    width: 64px;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    border: 0;
  }
  .slider4 {
    display: flex;
    justify-content: center;
  }
  .ant-tabs-bar {
    margin: 0px 16px 16px 16px;
    border-bottom: none;
  }
  .ant-tabs.ant-tabs-top.mbl-dev-ChildsTab.ant-tabs-line > div.ant-tabs-bar.ant-tabs-top-bar > div > div > div > div > div:nth-child(1) {
    margin: 5px 20px;
    border-bottom: 0.5px solid #BDBDBD;
  }
  .ant-tabs-nav {
    font-size: 16px;
  }
  .ant-tabs.ant-tabs-top.mbl-dev-ChildsTab.ant-tabs-line > div.ant-tabs-bar.ant-tabs-top-bar > div > div > div > div > div.ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    width: 165px!important;
    height: 3px!important;
    margin: 4px 20px!important;
  }
  .ant-tabs.ant-tabs-top.mbl-dev-ChildsTab.ant-tabs-line > div.ant-tabs-bar.ant-tabs-top-bar > div > div > div > div > div:nth-child(1) > div:nth-child(1) {
    margin: 0 15px 0 0;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    color: #0076FF;
    font-weight: 800;
    font-size: 16px;
  }
  .ant-calendar-picker-container {
    left: 50px!important;
  }
  .site-custom-tab-bar {
    z-index: 200;
    background: #fafafa;
    height: 55px;
  }
}

@media only screen and (min-width: 576px) {
  .mbl-students-det, .mbl-dev-student-name, .mbl-dev-Childmodal, 
  .mbl-student-container, .mbl-dev-carddet, .mbl-dev-ChildsTab {
    display: none!important;
  }
  .ant-calendar {
    width: 100%;
  }
  .ant-calendar-disabled-cell > .journal-date {
    width: 50px;
  }
  .journal-date {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 50px;
    width: 50px;
    padding: 0 4px 8px 4px;
    font-size: 16px;
    /*border: 1px solid #0076FF;*/
  }
}
