.caption{
    font-size: 16px;
}
svg > g > g:nth-child(4){
		transform: scale(1.12);
		overflow: visible !important;
}
.shape {
	fill-opacity: .3;
}
.radarchart svg{
	    transform: scale(1.3);
    margin-top: 50px;
}
.shape:hover {
	fill-opacity: .65;
	z-index: 100;
}

.scale {
	fill: #FAFAFA;
	stroke: #999;
	stroke-width: .2
}

.axis {
	stroke: #555;
	stroke-width: .2
}

.dot {
  /* fill: white;
  stroke: #e7e8e7; */
  r: 3;
  transition: r .2s;
}

.dot:hover {
  stroke: #bbb;
  r: 3;
}

.caption {
	fill: #444;
	    font-family: 'Avenir' !important;
    font-weight: 500!important;
	text-shadow: 1px 1px 0 #fff
}