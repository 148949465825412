.notification-card {
  box-shadow: 0 1px 6px rgba(74, 144, 226, 0.5) !important;
  border-radius: 5px !important;
  min-height: calc(100vh - 100px);
}

.notification-card .ant-card-body{
  padding: 0;
}

.notification-card .ant-menu-vertical{
  height: calc(100vh - 103px);
  overflow: scroll;
}

.notification-card .ant-menu-vertical .ant-menu-item {
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  height: 55px;
  padding: 0.5rem 1rem;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.notification-card .ant-menu-vertical .ant-menu-item .notification-count{
  min-height: 27px;
  min-width: 48px;
  max-width: 48px;
  max-height: 27px;
  border-radius: 30px;
  background: #C8C8C8;
  color: #FFFFFF;
  padding: 0.25rem 1rem;
  font-size: 14px;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notification-card .notification-content {
  border-left: 1px solid #e8e8e8;
}

.notification-card .ant-menu-vertical .ant-menu-item-selected {
  background-color: #FFFFFF;
  border-right: 2px solid #1890FF;
}

.notification-card .ant-menu-vertical .ant-menu-item-selected .notification-count{
  background: #0084FF;
}

.notification-card .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border: none;
}

.single-notification {
  font-family: Avenir, sans-serif;
  font-style: normal;
  font-weight: 500;
  border-bottom: 1px solid #C8C8C8;
  height: 55px;
  font-size: 16px;
  padding: 0.5rem 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.display-name {
  font-weight: 800;
  color: #000000;
}

.no-notifications-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #4A4A4A;
  font-size: 20px;
  margin-top: 24px;
}

.notification-table .anticon svg {
  margin: 0;
}
.lcApproval .ant-table-body td{
  padding:0;
}
.lcApproval td div{
  padding:16px
}
.lcApproval a,.lcApproval a:hover{
  text-decoration: none;
  color: inherit;
}