@media only screen and (max-width: 576px) {
    .max-dev-cart, .max-dev-emptycart {
      display: none!important;
    }
    .padding-12 {
        padding: 12px!important;
    }
    .purchase-heading {
        font-size: 20px;
    }
    .selectchildtext, .border-bottom {
        margin-top: .5rem!important;
    }
    .heading {
        font-weight: 800!important;
        font-size: 20px!important;
    }
    .hurryText {
        font-size: 14px!important;
    }
    .btn-save {
        font-weight: 500;
        font-size: 18px;
        color: #0076FF!important;
    }
    .btn-del {
        font-weight: 500;
        font-size: 18px;
        color: #EA1F59!important;
    }
    .mb-6rem {
        margin-bottom: 6rem!important;
    }
    .payment-totalAmount {
        font-size: 24px;
        font-weight: 800;
        font-size: 24px;
        line-height: 33px;
        color: #000000;
    }
    .payment-purchaseSummary {
        cursor: pointer;
        font-size: 14px;
        line-height: 161.1%;
        color: #0076FF;
    }
    .btn-payment {
        font-size: 14px;
        font-weight: 800;
    }
}

@media only screen and (min-width: 576px) {
    .mbl-dev-cart, .mbl-dev-emptycart {
      display: none!important;
    }
}